<template>
  <div class="mappy-filter-data-block">
    <div style="width: 50%">
      <Multiselect
        v-model="chosenChassis"
        :placeholder="translate('data->Filter vehicles')"
        mode="tags"
        :options="chassisOptions"
        :close-on-select="false"
        :searchable="true"
        :clear-on-select="false"
        @change="updateSelectedChassis"
      />
    </div>
    <div
      style="width: 50%"
    >
      <Multiselect
        v-model="chosenLocation"
        :placeholder="translate('data->Filter locations')"
        mode="tags"
        :options="chassisLocations"
        :close-on-select="false"
        :searchable="true"
        :clear-on-select="false"
        @change="updateSelectedLocation"
      />
    </div>
    <div style="width: 70%">
      <div>
        <date-filter
          :key="componentKey"
          @date-update="updateDateRange"
        />
      </div>
      <div>
        <h6 style="font-size: 10px;color:white;">
          * {{ translate("data->Selected date range is ") + computedDateInterval }}
        </h6>
      </div>
    </div>
    <div style="width: 10%;">
      <v-btn
        class="mappy-filter-data"
        :disabled="disableFilterButton"
        @click="fetchFilteredData"
      >
        <font-awesome-icon
          icon="filter"
          style="cursor: default;color: white;height: 20px;"
        />
      </v-btn>
    </div>
    <div style="width: 10%;">
      <v-btn
        class="mappy-filter-data"
        :disabled="disableFilterButton"
        @click="clearAndFetchFilteredData"
      >
        <font-awesome-icon
          icon="filter-circle-xmark" 
          style="cursor: default;color: white;height: 20px;"
        />
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import DateFilter from "@/components/DateFilter.vue"
import { useEventsBinding, useMap } from "@/composables"
import { useDataFetcher } from "@/composables/useCustomerDataFetcher"
import { log } from "@/plugin/logger"
import { workerManager } from "@/services/duckWorkerManager"
import { useFleetStore } from "@/store/fleet/fleetStore"
import { useI18nStore } from "@/store/localeStore"
import { useMainStore } from "@/store/mainStore"
import { useSetStore } from "@/store/setStore"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import { getActivePinia } from "pinia"
import { computed, defineEmits, nextTick, onMounted, ref, unref } from "vue"


const mainStore = useMainStore()
const fleetStore = useFleetStore()
const dataStore = useSetStore()

const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}
const emit = defineEmits(["update-size", "handle-data-ready","date-update"])
const { map } = useMap()

const chosenChassis = ref([])
const chosenLocation = ref([])

const get1monthAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-01"
  }

  const currentDate = new Date() // Today
  currentDate.setMonth(currentDate.getMonth() - 1) // Subtract 1 month

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const today = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-30"
  }
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate())

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}
const dateRange = ref({min_date:get1monthAgoDate(), max_date: today()})
const mapevents = [
  "deckfeaturesselected"
]
useEventsBinding(emit, map, mapevents)

const getFilter = () => {
  return {
    ...dateRange.value,
    ...(chosenChassis.value.length > 0 ? {reg_no: chosenChassis.value.join(",")} : {}),
    ...(chosenLocation.value.length > 0 ? {origin: chosenLocation.value.join(",")} : {})
  }
}

const apiConfigurations = [
  { workerName: "hexesAPI", filename: "stops", setName: ["locationAggregates", "locationAggregatesUnfiltered"], queryFunction: "get_location_aggregates", message: "Hexes is requested", requiresFilter: true},
  { workerName: "edgesAPI", filename: "stops", setName: ["edgeAggregates", "edgeAggregatesUnfiltered"], queryFunction: "edges", message: "Edges is requested", requiresFilter: true},
  { workerName: "stopDuration7DaysAPI",filename: "stops", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedStopDuration7DaysAPI", filename: "stops", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "stopDurationPredictionAPI", filename: "stops", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", message: "get_stop_duration_this_month_prediction is requested", requiresFilter: true},
  { workerName: "weightUnloaded7DaysAPI",filename: "stops", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedWeightUnloaded7DaysAPI", filename: "stops", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "dataForStoryComponent", filename: "stops", setName: "storyData", queryFunction: "get_status", message: "get_status is requested", requiresFilter: true},
  { workerName: "topLocations", filename: "stops", setName: "topLocations", queryFunction: "get_location_top", message: "get_location_top is requested", requiresFilter: true},
  { workerName: "topVehicles", filename: "stops", setName: "topVehicles", queryFunction: "get_vehicle_top", message: "get_vehicle_top is requested", requiresFilter: true },
  { workerName: "topFuelConsumptionVehicles", filename: "stops", setName: "topFuelConsumptionVehicles", queryFunction: "get_fuel_consumption_vehicle_top", message: "get_fuel_consumption_vehicle_top is requested", requiresFilter: true},
  { workerName: "kpiLocation", filename: "kpi", setName: "kpiLocation", queryFunction: "get_fleet_kpi", message: "get_fleet_kpi is requested", requiresFilter: false},
  { workerName: "vehicleRegNos", filename: "stops", setName: "vehicleRegNos", queryFunction: "get_regno", message: "get_regno is requested", requiresFilter: true  },
  { workerName: "vehicleAggregates", filename: "stops", setName: "vehicleAggregates", queryFunction: "get_vehicle_aggregates", message: "get_vehicle_aggregates is requested",  requiresFilter: true }
]
const apiConfigurationsRef = ref(apiConfigurations)

const findSetNameByWorkerName = (workerName) => {
  const config = apiConfigurations.find(config => config.workerName === workerName)
  return config ? config.setName : null
}
const workerCount = ref(0)
const handleDataReady = (data, workerName) => {
  log("info", `${workerName} is filtered and fetched!`)
  workerCount.value++

  const setNames = [].concat(findSetNameByWorkerName(workerName))
  const pinia = getActivePinia()
  if (!pinia) return []
  setNames.map(dataSetName => {
    dataStore.setAggregate(dataSetName, data.result)
  })

  const storeNames = Object.keys(pinia.state.value).filter(key => key.toLowerCase().includes("map"))

  storeNames.forEach((storeId) => {
    const store = pinia._s.get(storeId)
    if (store && typeof store.setAggregate === "function") {
      setNames.map(dataSetName => {
        store.setAggregate(dataSetName, data.result)
      })
    }
  })

//  emit("handle-data-ready", dataSetName)
  triggerMapDataChangeOnCoupledLayers()
}

const handleError = (error) => {
  log("error", `Error occurred: ${error}`)
}

const { postMessageToDuckAPI, registerListeners } = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady,
  onError: handleError
})

const fetchFilteredData = () => {
  const duckSource = mainStore.duckDBSource
  console.log("DuckSource:",duckSource)
  apiConfigurationsRef.value.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    const configWithOptionalFilters = apiConfig.requiresFilter ? {...apiConfig, filters: getFilter(), filename: duckSource} : apiConfig
    postMessageToDuckAPI(worker, configWithOptionalFilters)
  })
}
const componentKey= ref(0)
const clearAndFetchFilteredData = () => {
  console.log("ClearAndFetchFilteredData")
  mainStore.setDuckSource("prompt")
  chosenChassis.value=[]
  chosenLocation.value=[]
  dateRange.value={min_date:get1monthAgoDate(), max_date: today()}
  componentKey.value++
  fetchFilteredData()

}
const chassisOptions = computed(() => {
  return dataStore.getAggregate("vehicleRegNos") ? dataStore.getAggregate("vehicleRegNos").flatMap(vehicle => { return vehicle.reg_no}) : []//.flatMap(vehicle => { return vehicle.reg_no})
})

const chassisLocations = computed(() => {
  return  dataStore.getAggregate("locationAggregates") ?  dataStore.getAggregate("locationAggregates").flatMap(location => { return location.hexagons}) : []//.flatMap(vehicle => { return vehicle.reg_no})
})
// return  dataStore.getAggregate("locationAggregates") ?  dataStore.getAggregate("locationAggregates").flatMap(location => { return location.hexagons}) : []//.flatMap(vehicle => { return vehicle.reg_no})


function extractMbIds(mapLayers) {
  const mbIds = []
  for (const layerType in mapLayers) {
    const layers = mapLayers[layerType]
    layers.forEach(layer => {
      if (layer.mbId) {
        mbIds.push(layer.mbId)
      }
    })
  }
  return mbIds
}

const allMapLayers = computed(() => {
  const pinia = getActivePinia()
  if (!pinia) return []

  const storeNames = Object.keys(pinia.state.value).filter(key => key.toLowerCase().includes("map"))
  const allMbIds = []

  storeNames.forEach((storeId) => {
    const storeState = pinia.state.value[storeId]
    if (storeState.mapLayers) {
      const mbIds = extractMbIds(storeState.mapLayers)
      allMbIds.push(...mbIds)
    }
  })

  return allMbIds
})

const disableFilterButton = computed(() => workerCount.value > 0 && workerCount.value < apiConfigurations.length)
const updateSelectedChassis = () => {
  fleetStore.setSelectedChassis(chosenChassis.value)
}
const updateSelectedLocation = () => {
  console.log("Location filter selected")
}
const updateDateRange = ([minDate, maxDate]) => {
  dateRange.value = {min_date: minDate, max_date: maxDate}
}

const computedDateInterval = computed(() => {
  if (dateRange.value.min_date && dateRange.value.max_date) {
    const minDate = new Date(dateRange.value.min_date)
    const maxDate = new Date(dateRange.value.max_date)
    const diffTime = Math.abs(maxDate - minDate)
    const diffDays = (Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + 1
    return diffDays + " " + translate("data->days")
  }
  return "30 " + translate("data->days")
})

const triggerMapDataChangeOnCoupledLayers = () => {
  nextTick(() => {
    allMapLayers.value.forEach(l => unref(map).fire("layerDataChanged", { layerName: l }) )
  })
}

onMounted(() => {
  registerListeners()
})

defineExpose({
  fetchFilteredData
})

</script>

<style scoped>
.mappy-filter-data-block {
  display: flex;
  flex-direction: row;
  gap: 10px;
}



.mappy-filter-data {
  background-color: deeppink;
  border: none;
  border-radius:5px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  margin-left: 2px;
  min-width: 5px;
  max-width: 15px;
}


</style>