<template>
  <div class="ask-mappy-container">
    <div class="input-section">
      <input
        v-model="userInput"
        type="text"
        placeholder="Ask Mappy..."
      >
      <button @click="postQuestion">
        <i class="fas fa-brain" /> Post
      </button>
    </div>
    <div
      v-if="isLoading"
      class="loading-container"
    >
      <div class="spinner" />
      <span>Mappy is thinking...</span>
    </div>
    <div
      v-if="response"
      class="response-container"
    >
      <div class="response-content">
        <div
          v-if="response.summary"
          class="question-section"
        >
          <div class="label">
            Summary:
          </div>
          <div class="content question-content">
            {{ response.summary }}
          </div>
        </div>
        <div class="answer-section">
          <div class="label">
            Query:
          </div>
          <pre class="content">
            {{ formatResponse(response.query) }}
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDataFetcher } from "@/composables/useCustomerDataFetcher"
import { workerManager } from "@/services/duckWorkerManager"
import { useMainStore } from "@/store/mainStore"
import { defineEmits, onMounted, onUnmounted, ref } from "vue"

const userInput = ref("")
const response = ref("")
const mainStore = useMainStore()
const emit = defineEmits(["fetch-data", "refresh-data"])
const isLoading = ref(false)

const formatSQLQuery = (sql) => {
  console.log(sql)
  return sql
    .split("\n")  // First split on existing newlines
    .map(line => line.trim())  // Trim each line
    .join("\n")  // Rejoin with newlines
    .replace(/,(?!\n)/g, ",\n    ")  // Add newlines after commas if not already there
    .replace(/\s+FROM/gi, "\nFROM")
    .replace(/\s+WHERE/gi, "\nWHERE")
    .replace(/\s+LEFT JOIN/gi, "\nLEFT JOIN")
    .replace(/\s+GROUP BY/gi, "\nGROUP BY")
    .replace(/\s+ORDER BY/gi, "\nORDER BY")
    .replace(/\s+LIMIT/gi, "\nLIMIT")
    .replace(/;/g, ";\n")
    .trim()  // Remove any leading/trailing whitespace
}

const decodeResponse = (response) => {   
    if (response.summary && response.query) {
        return {
            summary: response.summary,
            query: response.query
        }
    }
    return {
        summary: response,
        query: ""
    }
}

const handleDataReady = (data, workerName) => {
  console.log(`${workerName} data is ready:`, data)
  if (data?.value?.result) {
    response.value = data.value.result
  } else if (data?.result) {
    response.value = data.result
  } else {
    response.value = data
  }
}

const handleWorkerResponse = (event) => {
  console.log("Worker response received:", event.data)
  try {
    if (event.data.key === "done") {
      const result = event.data.value.result
      console.log("Result type:", typeof result)
      console.log("Raw result:", result)
      
      // If result is already an object with query and summary
      if (result && typeof result === "object" && "query" in result && "summary" in result) {
        response.value = result
      }
      // If result is a string that needs parsing
      else if (typeof result === "string") {
        try {
          const parsedResult = JSON.parse(result)
          if (parsedResult.message) {
            response.value = {
              summary: parsedResult.message,
              query: ""
            }
          } else if (parsedResult.query && parsedResult.summary) {
            response.value = parsedResult
          } else {
            response.value = {
              summary: "Unexpected response format",
              query: ""
            }
          }
        } catch (parseError) {
          console.error("Parse error:", parseError)
          response.value = {
            summary: result,
            query: ""
          }
        }
      } else {
        response.value = {
          summary: String(result),
          query: ""
        }
      }
      
      // console.log("Final response:", response)
      isLoading.value = false
      emit("fetch-data")
    }
  } catch (error) {
    console.error("Error processing worker response:", error)
    response.value = {
      summary: "An error occurred while processing the response",
      query: ""
    }
    isLoading.value = false
  }
}

onMounted(() => {
  // Register the listener for the prompt worker
  workerManager.registerListener("prompt", handleWorkerResponse)
})

onUnmounted(() => {
  // Deregister the listener when component is unmounted
  workerManager.deregisterListener("prompt")
})

const apiConfigurationsRef = ref([
  {
    workerName: "prompt",
    filename: "stops",
    setName: "prompt",
    queryFunction: "prompt",
    message: "Prompt request",
    requiresFilter: false
  }
])

const { postMessageToDuckAPI } = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady
})

const postQuestion = () => {
  isLoading.value = true
  mainStore.setDuckSource("prompt")
  const config = {
    ...apiConfigurationsRef.value[0],
    filename: userInput.value
  }
  postMessageToDuckAPI(workerManager.getWorker(config.workerName), config)
}

const formatResponse = (response) => {
  console.log(response)
  if (typeof response === "string") {
    if (response.trim().toUpperCase().startsWith("SELECT")) {
      return formatSQLQuery(response)
    }
    // Handle string with \n characters
    return response.replace(/\\n/g, "\n").trim()
  }
  
  // If it's an object or array, format it as JSON
  if (typeof response === "object") {
    return JSON.stringify(response, null, 2)
  }
  
  return response
}
</script>

<style scoped>
.ask-mappy-container {
  position: absolute;
  top: 60px;
  right: 60px;
  width: 400px;
  max-height: calc(100vh - 80px);
  background: #ffffff !important; /* Solid white background */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.input-section {
  display: flex;
  gap: 10px;
  width: 100%;
}

.input-section input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.input-section button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.response-container {
  width: 100%;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  background: #f5f5f5 !important;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  z-index: 1;
}

.response-content {
  z-index: 1;
  background: #f5f5f5 !important;
  border-radius: 6px;
  overflow: hidden;
}

.question-section {
  z-index: 1;
  background: #e9ecef !important;
  border-bottom: 1px solid #dee2e6;
  padding: 12px;
  height: 120px;
}

.question-content {
  background: #e9ecef !important;
  padding: 8px 0;
  margin-top: 4px;
}

.answer-section {
  padding: 12px;
  background: #f5f5f5 !important;
  z-index: 1;
}

.label {
  font-weight: 600;
  color: #495057;
  margin-right: 8px;
  font-size: 13px;
  background: inherit !important;
}

.content {
  color: #212529;
  font-size: 13px;
  background: inherit !important;
}

.answer-section pre.content {
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #ffffff !important;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin: 8px 0 0;
  line-height: 1.4;
  overflow-x: auto;
  user-select: text;
  cursor: text;
  font-size: 12px;
  max-height: 500px;
}

.answer-section pre.content:hover {
  background-color: #f8f9fa;
}

/* Scrollbar styling */
.response-container::-webkit-scrollbar,
.answer-section pre.content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.response-container::-webkit-scrollbar-track,
.answer-section pre.content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.response-container::-webkit-scrollbar-thumb,
.answer-section pre.content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.response-container::-webkit-scrollbar-thumb:hover,
.answer-section pre.content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.response-container {
  animation: fadeIn 0.5s ease-out;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 4px;
  margin-top: 10px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>