<template>
  <div :id="id" />
</template>

<script setup>
import {
  defaultColour,
  getAutomaticBinsForColourScaleInDomain,
  getColorArrayForSchema,
  useEventsBinding,
  useMap
} from "@/composables/index.js"
import { H3HexagonLayer } from "@deck.gl/geo-layers"
import { MapboxLayer } from "@deck.gl/mapbox"
import { cellToLatLng } from "h3-js"
import "mapbox-gl/dist/mapbox-gl.css"
import { computed, inject, nextTick, onMounted, onUnmounted, ref, unref, watch } from "vue"

import { useDataFetcher } from "@/composables/useCustomerDataFetcher"
import { log } from "@/plugin/logger.js"
import { workerManager } from "@/services/duckWorkerManager"
import { useI18nStore } from "@/store/localeStore"
import { useMainStore } from "@/store/mainStore"
import { useSetStore } from "@/store/setStore"
import * as h3 from "h3-js"
import * as turf from "turf"

const events = [
  "decklayervisible",
  "decklayercolorstyle",
  "decklayerelevationstyle",
  "decklayerelevationscalestyle",
  "decklayercolorrampstyle",
  "decklayerfilter",
  "deckfeaturesselected",
  "layerDataChanged",
  "styleChanged"
]

const { map } = useMap()
const isLoaded = ref(false)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  beforeId: {
    type: String,
    default: undefined
  },
  getDataFunction: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    default: () => {}
  },
  keyForScale: {
    type: String,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  colorSchema: {
    type: Object,
    default: defaultColour
  },
  h3Key: {
    type: String,
    default: "hexagons"
  },
  keyForElevation: {
    type: String,
    default: "sum_duration"
  }
})

const i18nStore = useI18nStore()
const mainStore = useMainStore()
const dataStore = useSetStore()
const useCustomStore = inject(props.namedModule)
const hexagonSelected = ref(false)
const selectedHexagonId = ref("")
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const dataInMap = computed(() => {
  return useCustomStore.getAggregate(props.accessorKeyForDataFunction)
})

const getH3ResolutionForZoom = (zoom) => {
  if (zoom >= 8) return 10
  if (zoom >= 7) return 9
  if (zoom >= 6) return 8
  if (zoom >= 5) return 7
  if (zoom >= 4) return 6
  if (zoom >= 3) return 5
  if (zoom >= 2) return 4
  if (zoom >= 1) return 3
  return 2
}

const fetchLocationData = () => {
  const zoom = unref(map).getZoom()
  const resolution = getH3ResolutionForZoom(zoom)
  const data = dataStore.getAggregate(props.accessorKeyForDataFunction)


  return data.map(item => {
    const hex = h3.cellToParent(item[props.h3Key], resolution)
    return { ...item, [props.h3Key]: hex }
  })
}

const transposeCoordinates = h3CellIndex => {
  let invertedCoordinates = cellToLatLng(h3CellIndex)
  return [invertedCoordinates[1], invertedCoordinates[0]]
}

const defaultColorScheme = props.colorSchema.map((colours) => { return colours })
const colourArray = getColorArrayForSchema(defaultColorScheme)
const keyForColour = ref(props.keyForScale)
const initData = dataInMap.value ? dataInMap.value : fetchLocationData()
const forScale = getAutomaticBinsForColourScaleInDomain(initData, keyForColour.value, colourArray)
const colourScale = ref(forScale)
const filterDesc = ref()
const lastEvent = ref()

const hexagonLayer = new MapboxLayer({  
  id: props.id,
  type: H3HexagonLayer,
  data: fetchLocationData().filter(hexagon => {
    const elevation = hexagon[props.keyForElevation]
    return elevation !== null && elevation !== undefined && elevation > 0
  }),
  autoHighlight: true,  
  getHexagon: hexagon => hexagon[props.h3Key],
  opacity: 0.8,
  getFillColor: hexagon => {
    return colourScale.value(hexagon[keyForColour.value])
  },
  pickable: true,
  extruded: true,
  elevationScale: fetchLocationData() && fetchLocationData().length ? 1 : 0.5,
  getElevation: hexagon => {
    const elevation = hexagon[props.keyForElevation] || 0
    return elevation
  },
  parameters: {
    depthTest: true,
    depthMask: true
  },
  updateTriggers: {
    getElevation: [props.keyForElevation],
    data: [props.keyForElevation],
    getFillColor: true
  },
  onClick: (hexagon, event) => {
    event.preventDefault()
    event.stopPropagation()
    const hexagonId = hexagon.object[props.h3Key]

    if (selectedHexagonId.value === hexagonId) {
      // Deselect the hexagon if it's already selected
      hexagonSelected.value = false
      selectedHexagonId.value = null
      updateSelectedHexagons({ layerName: props.id, featureIds: "" })
      apiConfigurationsRef.value.forEach(apiConfig => {
        const worker = workerManager.getWorker(apiConfig.workerName)
        const configWithoutFilters = apiConfig.requiresFilter ? {...apiConfig, filters: getFilter(), filename: mainStore.getDuckSource} : {...apiConfig, filename: mainStore.getDuckSource}
        postMessageToDuckAPI(worker, configWithoutFilters)
      })

    } else {
      hexagonSelected.value = true
      selectedHexagonId.value = hexagonId
      log("info", hexagonId)
      updateSelectedHexagons({ layerName: props.id, featureIds: hexagonId, minDate: hexagon.object["min_date"] })
      fetchHexData(hexagonId)

      const toThePopup = {
        coordinates: transposeCoordinates(hexagonId),
        properties: hexagon.object
      }
      emit("mb-layer-click", toThePopup)
    }
  }
})
const apiConfigurations = [
  { workerName: "hexesAPI", filename: "stops", setName: ["locationAggregates", "locationAggregatesUnfiltered"], queryFunction: "get_location_aggregates", message: "Hexes is requested", requiresFilter: true},
  { workerName: "edgesAPI", filename: "stops", setName: ["edgeAggregates", "edgeAggregatesUnfiltered"], queryFunction: "edges", message: "Edges is requested", requiresFilter: true},
  { workerName: "stopDuration7DaysAPI",filename: "stops", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedStopDuration7DaysAPI", filename: "stops", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "stopDurationPredictionAPI", filename: "stops", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", message: "get_stop_duration_this_month_prediction is requested", requiresFilter: true},
  { workerName: "weightUnloaded7DaysAPI",filename: "stops", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedWeightUnloaded7DaysAPI", filename: "stops", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "dataForStoryComponent", filename: "stops", setName: "storyData", queryFunction: "get_status", message: "get_status is requested", requiresFilter: true},
  { workerName: "topVehicles", filename: "stops", setName: "topVehicles", queryFunction: "get_vehicle_top", message: "get_vehicle_top is requested", requiresFilter: true },
  { workerName: "topLocations", filename: "stops", setName: "topLocations", queryFunction: "get_location_top", message: "get_location_top is requested", requiresFilter: true},
  { workerName: "topFuelConsumptionVehicles", filename: "stops", setName: "topFuelConsumptionVehicles", queryFunction: "get_fuel_consumption_vehicle_top", message: "get_fuel_consumption_vehicle_top is requested", requiresFilter: true},
  { workerName: "kpiLocation", filename: "kpi", setName: "kpiLocation", queryFunction: "get_fleet_kpi", message: "get_fleet_kpi is requested", requiresFilter: false},
  { workerName: "vehicleRegNos", filename: "stops", setName: "vehicleRegNos", queryFunction: "get_regno", message: "get_regno is requested",requiresFilter: true},
  { workerName: "vehicleAggregates", filename: "stops", setName: "vehicleAggregates", queryFunction: "get_vehicle_aggregates", message: "get_vehicle_aggregates is requested", requiresFilter: true }
]
const apiConfigurationsRef = ref(apiConfigurations)

const fetchHexData = ( filterValue, dates = null) => {
  apiConfigurationsRef.value.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    const baseFilter = dates || {}
    const filters = apiConfig.requiresFilter ? { ...baseFilter, ...getFilter( filterValue) } : {}
    const configWithFilters = { ...apiConfig, filters, filename: mainStore.getDuckSource }
    postMessageToDuckAPI(worker, configWithFilters)
  })
}
const getFilter = ( filterValue) => {
  const baseFilter = {
    min_date: dateRange.value[0].toISOString().split("T")[0],
    max_date: dateRange.value[1].toISOString().split("T")[0]
  }
  return { ...baseFilter, origin: filterValue }

}
const handleDataReady = (data, workerName) => {
  log("info", `${workerName} is filtered and fetched!`)

}


const { postMessageToDuckAPI} = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady
})

const get1monthAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-01"
  }

  const currentDate = new Date() // Today
  currentDate.setMonth(currentDate.getMonth() - 1) // Subtract 1 month

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const dateRange = ref([
  new Date(get1monthAgoDate()),
  new Date()
])


function handleDeckLayerDataUpdated (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        data: dataInMap.value
      },
      data: dataInMap.value
    })
  }
}

function handleDeckLayerVisibility (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      visible: event.visibility
    })
  }
}

function handleDeckLayerColorChange (event) {
  if (event.layerName === props.id) {
    keyForColour.value = event.colorPropKey
    colourScale.value = event.colourScale
    hexagonLayer.setProps({
      updateTriggers: {
        getFillColor: true,
        visible: true
      },
      getFillColor: hexagon => {
        return colourScale.value(hexagon[keyForColour.value])
      }
    })
    
    // Trigger a repaint after updating the layer properties
    nextTick(() => {
      unref(map).triggerRepaint()
    })
  }
}

function handleDeckLayerElevationChange(event) {
  if (event.layerName === props.id) {
      const filteredData = fetchLocationData().filter(hexagon => {
      const elevation = hexagon[event.elevationPropKey]
      const isVisible = elevation !== null && elevation !== undefined && elevation > 0
      return isVisible
    })
        
    hexagonLayer.setProps({
      data: filteredData,
      getElevation: hexagon => {
        const elevation = hexagon[event.elevationPropKey] || 0
        return elevation
      },
      updateTriggers: {
        getElevation: [event.elevationPropKey],
        data: filteredData
      },
      parameters: {
        depthTest: true,
        depthMask: true
      },
      opacity: 0.8,
      pickable: true,
      visible: true,
      extruded: true
    })

    // Force a redraw with a slight delay to ensure props are updated
    nextTick(() => {
      setTimeout(() => {
        if (unref(map)) {
          console.log("Triggering map repaint")
          unref(map).triggerRepaint()
        }
      }, 100)
    })
  }
}

function handleDeckLayerElevationScaleChange (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        elevationScale: event.elevationScalePropKey
      },
      elevationScale: event.elevationScalePropKey
    })
  }
}

function filterData(data, filters) {
  return data.filter(obj => {
    return filters.every(filter => {
      const fieldValue = obj[filter.selectedTexts[0]]
      if (filter.selectedInputType === "number") {
        return fieldValue >= parseFloat(filter.selectedRanges[0]) && fieldValue <= parseFloat(filter.selectedRanges[1])
      } else if (filter.selectedInputType === "date") {
        const startDate = new Date(filter.selectedDates[0])
        const endDate = new Date(filter.selectedDates[1])
        const objDate = new Date(fieldValue)
        return objDate >= startDate && objDate <= endDate
      } else {
        if (filter.selectedSplitStrings[0]!== ""&& filter.selectedSplitStrings.length>0 ) {
          return filter.selectedSplitStrings.some(function (element) {
            return fieldValue.includes(element)
          })
        } else {
          return true
        }
      }
    })
  })
}

function handleDeckLayerFilter (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        data:filterData(dataInMap.value, event.arrayFilter)
      },
      data:filterData(dataInMap.value, event.arrayFilter)
    })
  }
  filterDesc.value = translate("data->Total no. of locations/hexagons:") + " " + dataInMap.value.length + " ," + translate("data->Filtered no. of locations/hexagons:") + (filterData(dataInMap.value, event.arrayFilter).length)

  useCustomStore.setFilterDesc(filterDesc.value)
  nextTick(() => {
    unref(map).fire("filterDescription",
        {
          descFilter: filterDesc.value
        }
    )
  })
}

function handleDeckLayerColorRampChange (event) {
  if (event.layerName === props.id) {
    colourScale.value = event.colourScale
    keyForColour.value = event.colorPropKey
    hexagonLayer.setProps({
      updateTriggers: {
        getFillColor: true,
        visible: true
      },
      getFillColor: hexagon => {
        return colourScale.value(hexagon[keyForColour.value])
      }
    })
  }
}

const options = computed(() => {
  const opts = { ...props.options, id: props.id }
  if (opts.paint === null || opts.paint === undefined) {
    delete opts.paint
  }
  if (opts.layout === null || opts.layout === undefined) {
    delete opts.layout
  }
  return opts
})

useEventsBinding(emit, map, events, props.id)

/**
 * Remove the layer.
 */
function removeLayer() {
  if (typeof unref(map).getLayer(props.id) !== "undefined") {
    unref(map).removeLayer(props.id)
  }
}

const flyInOnLoad = () => {
  const locationAggregates = dataStore.getAggregate(props.accessorKeyForDataFunction)
  if (locationAggregates === null) return
  const maxObj = locationAggregates.reduce((prev, current) => (prev.no_stops > current.no_stops) ? prev : current)
  const maxHex = maxObj[props.h3Key]
  let [lat, lng] = transposeCoordinates(maxHex)

  // Adjust longitude to skew towards the right to fit the panel
  const latitudeOffset = -0.01
  lat -= latitudeOffset
  const target = {
    center: [lat, lng],
    zoom: 13
  }

  unref(map).flyTo({
    ...target,
    pitch: 60,
    duration: 6000,
    essential: true
  })
}

const updateSelectedHexagons = (event) => {
  if (event.layerName === props.id) {
    const selected = event.featureIds

    hexagonLayer.setProps({
      updateTriggers: {
        getFillColor: selected
      },
      getFillColor: hexagon => {
        // If no selection or empty selection, use the normal color scheme
        if (!selected || selected === "" || selected.length === 0) {
          return colourScale.value(hexagon[keyForColour.value])
        }
        // If there is a selection, highlight only selected hexagons
        if (selected.includes(hexagon[props.h3Key])) {
          return colourScale.value(hexagon[keyForColour.value])
        }
        return [128, 128, 128]
      }
    })

    if (selected.length === 0) emit("hex-deselect", { layerName: props.id })
    else if (!Array.isArray(selected) && selected.length > 0) {
      emit("hex-clicked", { layerName: props.id, hexId: selected, minDate: event.minDate })
      if (event.skipTableUpdate) flyToOnSelection(selected)
    }
    else if (Array.isArray(selected) && selected.length > 0) {
      emit("hexes-selected", { layerName: props.id, coordinates: event.centroid, selectedHexes: selected, minDate: event.minDate})
      if (event.skipTableUpdate) flyToOnSelection(selected[0])
    }
    if (!event.skipTableUpdate || event.skipTableUpdate === false) {
      unref(map).fire("mapfeaturesselected", { layerName: props.id, featureIds: selected })
    }
  }
}

const flyToOnSelection = (hexId) => {
  let [lat, lng] = transposeCoordinates(hexId)

  // Adjust longitude to skew towards the right to fit the panel
  const latitudeOffset = -0.01
  lat -= latitudeOffset
  const target = {
    center: [lat, lng],
    zoom: 13
  }

  unref(map).flyTo({
    ...target,
    pitch: 60,
    duration: 6000,
    essential: true
  })
}

const updateArea = (e) => {
  let selectedHexes

  const polygonCoordinates = e
    .features
    .filter(feature => feature.geometry.type === "Polygon")
    .map(polygon => polygon.geometry.coordinates)

  if (polygonCoordinates.length > 0) {
    let centroid
    const allHexesWithin = polygonCoordinates.reduce((acc, coords) => {
      const bboxPolygon = turf.polygon(coords)
      const bboxAreaInSqM = turf.area(bboxPolygon)

      // The max size for any polygon drawn in the map
      // This is to ensure that we don't gett OOM issues when filling larger areas
      // with hexagons.
      if (bboxAreaInSqM < 100000000) {
        return acc.concat(h3.polygonToCells(coords, 10, true))
      } else {
        alert("Oops! Your bounding box is too large. Please keep it under 100 km²")
        return acc
      }
    }, [])

    if (allHexesWithin.length > 0) {
      const extractedValues = dataInMap.value.map(obj => obj[props.h3Key]).flat()
      selectedHexes = allHexesWithin.filter(str => extractedValues.includes(str))
      const lastPolygonCoordinates = polygonCoordinates[polygonCoordinates.length - 1]
      const features = turf.polygon(lastPolygonCoordinates)
      centroid = turf.center(features).geometry.coordinates

      emit("polygon-selected", polygonCoordinates) //emit the bbox somewhere else?!
      log("info", "A new bbox was drawn for: "+ JSON.stringify(polygonCoordinates))
      updateSelectedHexagons({ layerName: props.id, coordinates: centroid, featureIds: selectedHexes, minDate: dataInMap.value[0].min_date})
    }
  }
}

// Force a layer update to ensure visibility is checked
function forceLayerUpdate() {
  hexagonLayer.setProps({
    data: fetchLocationData()
  })
  unref(map).triggerRepaint()
}

// Call forceLayerUpdate after setting props
forceLayerUpdate()

onMounted(() => {
  useCustomStore.setAggregate(props.accessorKeyForDataFunction, dataStore.getAggregate(props.accessorKeyForDataFunction))
  log("info", "Initiated a 'HexagonLayer' with " + props.id + " name.")
  removeLayer()
  unref(map).addLayer(hexagonLayer)
  useCustomStore.setMapLayerProp("Hexagon", parseInt(props.id.split("_").pop()), "mbId", props.id)

  log("info", "layer is added to map")
  unref(map).setLayoutProperty(props.id, "visibility", "visible")
  unref(map).triggerRepaint()

  isLoaded.value = true
  emit("hex-loaded")
  filterDesc.value = translate("data->Total no. of locations/hexagons:") + " " + dataInMap.value.length + " ," + translate("data->Filtered no. of locations/hexagons:") +  dataInMap.value.length

  useCustomStore.setFilterDesc(filterDesc.value)

  // if layer is the first layer added in the mappy app the make use of flyin feature
  if (props.namedModule.includes("mainMap") && props.id.split("_").pop() === "0") flyInOnLoad()

  // listen to custom event for deck
  map.value.on("decklayervisible", handleDeckLayerVisibility)
  map.value.on("decklayercolorstyle", handleDeckLayerColorChange)
  map.value.on("decklayerelevationstyle", handleDeckLayerElevationChange)
  map.value.on("decklayerelevationscalestyle", handleDeckLayerElevationScaleChange)
  map.value.on("decklayercolorrampstyle", handleDeckLayerColorRampChange)
  map.value.on("decklayerfilter", handleDeckLayerFilter)
  map.value.on("deckfeaturesselected", updateSelectedHexagons)
  map.value.on("layerDataChanged", handleDeckLayerDataUpdated)
  map.value.on("draw.updated", updateArea)

  map.value.on("styleChanged", function() {
    removeLayer()

    // Re-attaching the layer to the map when the custom event is heard
    unref(map).addLayer(hexagonLayer)
    unref(map).setLayoutProperty(props.id, "visibility", "visible")
  })
  map.value.on("zoom", () => {
    const newData = fetchLocationData()
    hexagonLayer.setProps({
      data: newData
    })
  })  
})

onUnmounted(() => {
  removeLayer()
})


watch(() => dataInMap.value, (newVal) => {
  unref(map).fire("layerDataChanged", { layerName: props.id })
}, { immediate: true })

</script>
