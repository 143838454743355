<script setup>
import {
  getAutomaticBinsForColourScaleInDomain, getColorArrayForSchema, getColorRamp, getColorRampDiv, getInvertedColourObj,
  useMap
} from "@/composables/index.js"
import { useFleetStore } from "@/store/fleet/fleetStore"
import { useI18nStore } from "@/store/localeStore"
import { useMainStore } from "@/store/mainStore"
import { useSetStore } from "@/store/setStore"
import { computed, defineEmits, inject, nextTick, onMounted, ref, unref } from "vue"

const props = defineProps({
  layerName: {
    type: String,
    required: true
  },
  keyAsString: {
    type: String,
    required: true
  },
  layerDataForDomain: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  },
  colorSchema: {
    type: Array,
    required: true
  },
  colorCount: {
    type: Number,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  colorSchemeName: {
    type: String,
    required: true
  },
  elevKeyAsString: {
    type: String,
    required: true
  },
  elevScaleAsString: {
    type: Number,
    required: true
  },
  sweete: {
    type: Boolean,
    default: false
  }
})


const useCustomStore = inject(props.namedModule)
const dataStore = props.sweete ? useFleetStore(): useSetStore()
const mainStore = useMainStore()
const { map } = useMap()

const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}


const domain = ref(dataStore.getAggregate(props.accessorKeyForDataFunction))
const colourArray = ref()
const colourScale = ref()
const colourObject = ref()
const colourObjectForEdges = ref()
const selectedAttribute = ref(props.keyAsString)
const selectedElevationAttribute = ref(props.elevKeyAsString)
const selectedElevationScaleAttribute = ref(props.elevScaleAsString)
const selectedColorRamp= ref(props.colorSchema)
const colourRamp= ref(getColorRamp(props.colorCount))
const divColourRamp= ref(getColorRampDiv(colourRamp.value,props.colorSchemeName))
const changeColorCount = ref(false)
const colourAttribute = ref(false)
const elevationAttribute = ref(false)
const elevationScaleAttribute = ref(false)
const changeColorSchema = ref(false)
const isSelected = ref(false)
let selectedHexagons=new Set()
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits(["update:colorCount"])
const layerIsVisible = computed(() => {
  if (unref(map)) {
    if (typeof unref(map).getLayer(props.layerName) !== "undefined") {
      return unref(map).getLayoutProperty(props.layerName, "visibility") === "visible"
    }
  }
  return false
})

const features = computed(() => {
  return selectableFeaturesInLayer()
})
const isNumeric = value => {
  if(value === null || value === "") return false
  const numberRegex = /^-?\d+(\.\d+)?$/
  return numberRegex.test(value.toString()) || !isNaN(value)
}

const selectableFeaturesInLayer = () => {
  return Object.keys(domain.value[0]).filter(feat => {
    const value = domain.value[0][feat]
    return isNumeric(value)
  })
}


const updateCount = (event) => {
  const targetVal = parseInt(event.target.value)
  if(targetVal > 2 && targetVal < 10) {
    const indexInArray = props.layerName.split("_").pop()
    useCustomStore.setMapLayerProp("Hexagon", indexInArray,  "colourCount", targetVal)

    colourRamp.value = getColorRamp(targetVal)
    nextTick(() => {
      unref(map).fire("decklayercolorrampstyle",
          {
            layerName: props.layerName,
            colorPropKey: selectedAttribute.value,
            colourScale: colourScale.value
          }
      )
    })
  }
 }

function handleSelectColor(color, schemeName) {
  selectedColorRamp.value = color
  divColourRamp.value = {
    colors: [(colourRamp.value).colors[(colourRamp.value).name.indexOf(schemeName)]],
    name: [schemeName]
  }
  const indexInArray = props.layerName.split("_").pop()
  useCustomStore.setMapLayerProp("Hexagon", indexInArray,  "colourSchema", selectedColorRamp.value)
  useCustomStore.setMapLayerProp("Hexagon", indexInArray,  "colorSchemeName",  divColourRamp.value.name[0])

  colourArray.value = getColorArrayForSchema(color)
  colourScale.value = getAutomaticBinsForColourScaleInDomain(domain.value, selectedAttribute.value, colourArray.value)
  colourObject.value = getInvertedColourObj(colourScale.value)

  nextTick(() => {
    unref(map).fire("decklayercolorrampstyle",
        {
          layerName: props.layerName,
          colorPropKey: selectedAttribute.value,
          colourScale: colourScale.value
        }
    )
  })
}

function handleSelectOption(event) {
  const indexInArray = props.layerName.split("_").pop()
  useCustomStore.setMapLayerProp("Hexagon", indexInArray,  "colourKey", event.detail.value)

  selectedAttribute.value = event.detail.value
  colourScale.value = getAutomaticBinsForColourScaleInDomain(domain.value, event.detail.value, colourArray.value)
  colourObject.value = getInvertedColourObj(colourScale.value)

  nextTick(() => {
    unref(map).fire("decklayercolorstyle",
        {
          layerName: props.layerName,
          colorPropKey: selectedAttribute.value,
          colourScale: colourScale.value
        }
    )
  })

}

function handleSelectElevation(event) {
  const indexInArray = props.layerName.split("_").pop()
  useCustomStore.setMapLayerProp("Hexagon", indexInArray,  "elevationKey", event.detail.value)

  selectedElevationAttribute.value = event.detail.value

  console.log("Emitting decklayerelevationstyle event:", {
    layerName: props.layerName,
    elevationPropKey: selectedElevationAttribute.value
  })

  nextTick(() => {
    unref(map).fire("decklayerelevationstyle",
        {
          layerName: props.layerName,
          elevationPropKey: selectedElevationAttribute.value
        }
    )
  })

}

function handleSelectElevationScale(event) {
  const indexInArray = props.layerName.split("_").pop()
  useCustomStore.setMapLayerProp("Hexagon", indexInArray,  "elevationScale", event.target.value ? parseInt(event.target.value) : 1)

  selectedElevationScaleAttribute.value = event.target.value?parseInt(event.target.value):1

  nextTick(() => {
    unref(map).fire("decklayerelevationscalestyle",
        {
          layerName: props.layerName,
          elevationScalePropKey: selectedElevationScaleAttribute.value
        }
    )
  })

}
const defaultFeature = selectableFeaturesInLayer().filter((feat) => feat.startsWith(props.keyAsString))[0]
const defaultElevationFeature = selectableFeaturesInLayer().filter((feat) => feat.startsWith(props.elevKeyAsString))[0]
const defaultColorScheme = divColourRamp

function setFilterRange (minValue, maxValue) {
  const filtered = domain.value
      .filter((h) => h[selectedAttribute.value] >= minValue && h[selectedAttribute.value] <= maxValue)
      .map(h => h.hexagons)

  let copySelectedHexes = new Set(selectedHexagons)
  for (let i = 0; i < filtered.length; i++) {
    let inSet = copySelectedHexes.has(filtered[i])
    if (inSet) {
      copySelectedHexes.delete(filtered[i])
    } else {
      if (filtered[i]) copySelectedHexes.add(filtered[i])
    }
  }
}

function selectHexagons (selectedHexes) {
  const copySelectedHexes = [...selectedHexes]
  let refreshedLayers = []
  let layer = map._value.__deck.layerManager._lastRenderedLayers.filter(l => l.id === props.layerName)[0]
  selectedHexagons = new Set(selectedHexes)
  let newLayer = layer.clone({
    getFillColor: (hexagon) => {
      const col = selectedHexagons.has(hexagon.hexagons)
          ? [255, 0, 128]
          : colourScale.value(hexagon[selectedAttribute.value])
      return col
    },
    updateTriggers: {
      getFillColor: copySelectedHexes
    }
  })
  const index = map._value.__deck.layerManager._lastRenderedLayers.findIndex(item => item.id === newLayer.id)
  map._value.__deck.layerManager._lastRenderedLayers[index] = newLayer

  refreshedLayers = map._value.__deck.layerManager._lastRenderedLayers

  map._value.__deck.setProps({ layers: refreshedLayers })
}

onMounted(() => {
  selectedAttribute.value = props.keyAsString
  selectedElevationAttribute.value = props.elevKeyAsString
  selectedElevationScaleAttribute.value = props.elevScaleAsString
  selectedColorRamp.value = props.colorSchema
  colourRamp.value = getColorRamp(props.colorCount)
  divColourRamp.value = getColorRampDiv(colourRamp.value,props.colorSchemeName)
  colourArray.value = getColorArrayForSchema(selectedColorRamp.value)
  colourScale.value = getAutomaticBinsForColourScaleInDomain(domain.value, selectedAttribute.value, colourArray.value)
  colourObject.value = getInvertedColourObj(colourScale.value)
  colourObjectForEdges.value = [{color:"#ff0080",colorFor:"Source"},{color:"#ffa200",colorFor:"Target"}]
})

</script>

<template>
  <tds-block
    class="tds-block"
  >
    <h6 class="tds-headline-06">
      {{ layerName }}
    </h6>
    <div
      v-if="layerIsVisible"
      class="layerDescription"
    >
      <div
        v-for="colour in colourObject"
        :key="colour.colour"
        class="legendRow"
      >
        <div
          class="colorIndicator"
          :style="{ background: colour.colour }"
          @click="setFilterRange(Math.round(colour.domain[0]), Math.round(colour.domain[1]))"
        />

        <div class="legendDescriptor">
          <div class="rangeValuesLegend">
            {{ Math.round(colour.domain[0]) }}
          </div>
          <div class="rangeValuesLegend">
            -
          </div>
          <div class="rangeValuesLegend">
            {{ Math.round(colour.domain[1]) }}
          </div>
        </div>
      </div>
      <div class="inputWrapper">
        <i
          id="colourAttributeInput"
          class="layerNameColorProp"
          @click="colourAttribute = !colourAttribute"
        >
          {{ translate("data->Colour based on:") }}
        </i><i
          id="colourAttributeInput"
          class="layerNameColorPropValue"
          @click="colourAttribute = !colourAttribute"
        >
          <b>{{ selectedAttribute }}</b>
        </i>
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <tds-dropdown
          v-if="colourAttribute"
          size="sm"
          open-direction="up"
          :placeholder="selectedAttribute"
          :default-option="defaultFeature"
          @tdsChange="handleSelectOption"
        >
          <tds-dropdown-option
            v-for="(option, index) in selectableFeaturesInLayer()"
            :key="index + '_' + option"
            :value="option"
          >
            {{ option }}
          </tds-dropdown-option>
        </tds-dropdown>
      </div>

      <div class="inputWrapper">
        <!-- eslint-disable vue/attribute-hyphenation -->
        <div
          class="colourSchemaSelected"
          @click="changeColorSchema = !changeColorSchema"
        >
          <i
            id="colourSchemaInput"
            class="layerNameColorProp"
          >
            {{ translate("data->Colours:") }}
          </i>

          <div
            v-for="(colours, index) in divColourRamp.colors"
            :key="colours"
            class="colorramp"
          >
            <div
              v-for="colour in colours"
              :key="colour"
              class="legendGradientKey"
              :title="divColourRamp.name[index]"
              :style="{'background':colour}"
            />
          </div>
        </div>

        <tds-dropdown
          v-if="changeColorSchema"
          size="sm"
          open-direction="up"
          :default-option="defaultColorScheme"
        >
          <tds-dropdown-option
            v-for="(colours, index) in colourRamp.colors"
            :key="colours"
            :value="colourRamp.name[index]"
            @click="handleSelectColor(colours, colourRamp.name[index])"
          >
            <div
              v-for="colour in colours"
              :key="colour"
              class="legendGradientKey"
              :title="colourRamp.name[index]"
              :style="{ 'background':colour}"
            />
          </tds-dropdown-option>
        </tds-dropdown>
      </div>

      <div class="inputWrapper">
        <i
          id="elevationAttributeInput"
          class="layerNameColorProp"
          @click="elevationAttribute = !elevationAttribute"
        >
          {{ translate("data->Elevation based on:") }}
        </i>
        <i
          id="elevationAttributeInput"
          class="layerNameColorPropValue"
          @click="elevationAttribute = !elevationAttribute"
        >
          <b>{{ selectedElevationAttribute }}</b>
        </i>
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <tds-dropdown
          v-if="elevationAttribute"
          size="sm"
          open-direction="up"
          :placeholder="selectedElevationAttribute"
          :default-option="defaultElevationFeature"
          @tdsChange="handleSelectElevation"
        >
          <tds-dropdown-option
            v-for="(option, index) in selectableFeaturesInLayer()"
            :key="index + '_' + option"
            :value="option"
          >
            {{ option }}
          </tds-dropdown-option>
        </tds-dropdown>
      </div>

      <div class="inputWrapper">
        <i
          id="elevationAttributeInput"
          class="layerNameColorProp"
          @click="elevationScaleAttribute = !elevationScaleAttribute"
        >
          {{ translate("data->Elevation Scale:") }}
        </i>
        <i
          id="elevationAttributeInput"
          class="layerNameColorPropValue"
          @click="elevationScaleAttribute = !elevationScaleAttribute"
        >
          <b>{{ selectedElevationScaleAttribute }}</b>
        </i>
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <tds-text-field
          v-if="elevationScaleAttribute"
          id="elevation-scale-text"
          name="elevation-scale-text-field"
          noMinWidth="true"
          :placeholder="selectedElevationScaleAttribute"
          type="text"
          :value="selectedElevationScaleAttribute"
          @input="handleSelectElevationScale"
        />
      </div>
      <!-- Colour count didn't have great user flow on the rest of the app so I moved the broken feature to a toggle for now... -->
      <div
        v-if="mainStore.getAppConfigFlag('userThatTestNewFeature')"
        class="inputWrapper"
      >
        <!-- eslint-disable vue/attribute-hyphenation -->
        <i
          id="colourCountInput"
          class="layerNameColorProp"
          @click="changeColorCount = !changeColorCount"
        >
          {{ translate("data->Number of colors:") }} {{ colorCount }}
        </i>
        <tds-text-field
          v-if="changeColorCount"
          name="colorCountInput"
          type="number"
          size="sm"
          label-position="outside"
          placeholder="Range (3 - 9)"
          :value="colorCount"
          @tdsChange="updateCount"
        />
      </div>

      <h6 class="tds-headline-06">
        {{ translate("data->Trip color:") }}
      </h6>
      <div
        v-for="colour in colourObjectForEdges"
        :key="colour.color"
        class="legendRow"
      >
        <div
          class="colorIndicator"
          :style="{ background: colour.color }"
        />

        <div class="legendDescriptor">
          <div class="rangeValuesLegend">
            {{ translate("data->"+colour.colorFor+" location") }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!layerIsVisible"
      class="layerDescription"
    >
      <h6 style="; color: black">
        {{ props.layerName }} {{ translate("data->is not visible on the map") }}.
      </h6>
    </div>
  </tds-block>
</template>

<style scoped>
.tds-block {
  color: black;
  padding-bottom: 1px;
}

.layerNameColorProp {
  font-style: italic;
  font-size: 10px;
  text-decoration: underline;
}
.layerNameColorPropValue {
  font-style: italic;
  font-size: 10px;
  text-decoration: none;
}

.colorIndicator {
  width: 16px;
  height: 16px;
}

.layerDescription {
  align-self: flex-start;
  width: 100%;
}
.legendRow {
  justify-content: left;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.legendDescriptor {
  margin: 0px 0px 0px 5px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
}
.legendGradientKey {
  margin-top: 5px;
  background: red;
  height: 15px;
  width: 100%;
}
.rangeValuesLegend {
  padding: 5px;
  justify-content: center;
  display: flex;
}
.colorramp {
  display: flex;
  z-index: 9;
  margin: 5px;
  width: 100px;
  justify-self: flex-end;
}

.inputWrapper {
  cursor: pointer;
}

.colourSchemaSelected {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

#colourSchemaInput {
  align-self: end;
}

</style>
